import { AiOutlineShoppingCart } from "react-icons/ai";
import { HiArrowSmRight } from "react-icons/hi";
import { PAYMENT_STEPS } from "../PaymentPage";

export function PaymentStageIndicator(props) {
  const { stage, products } = props;


  const second = stage >= PAYMENT_STEPS.Shipment;
  const third = stage >= PAYMENT_STEPS.Payment;

  
  return (
    <div className="pt-2 flex flex-wrap text-lg items-center">
      <div className="flex items-center">
        <AiOutlineShoppingCart size={20} />
        <h2 className="ml-2 font-medium">
          {`Carrinho (${Object.keys(products).length})`}
        </h2>
      </div>
      <div className={`${second?"":"text-stone-400"} ml-2 flex items-center`}>
        <HiArrowSmRight size={20} />
        <h2 className="ml-2 font-medium">{`Encomenda`}</h2>
      </div>
      <div className={`${third?"":"text-stone-400"} ml-2 flex items-center`}>
        <HiArrowSmRight size={20} />
        <h2 className="ml-2 font-medium">{`Pagar`}</h2>
      </div>
    </div>
  );
}
