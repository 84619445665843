import React, { useState, useEffect } from "react";
import { createBooking,useUser, getBookingSettings, getAllBookings } from '../../Api/Booking';
import { FaTimes } from 'react-icons/fa'; 
import { DateTimeButtons } from './DateTimeButtons';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IMAGE_DOMAIN } from '../../../../config/Constant';
import { getEmployeesList } from '../../../admin/Api/AdminServices';
import { BookingConfList } from './BookingConfList';
import CountryCodeOptions from '../../../../utils/CountryCodeOptions';

const BookingFormAdmin = ({ onClose,id  }) => {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const user = useUser(token) || { id: null };
  /* Selección y Carga de Usuarios */  
  const [customer_user, setCustomerUser] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeeSettings, setEmployeeSettings] = useState(null);
  /* Obtención, Llamada y Reccepción de Datos */
  const [settingsData, setSettingsData] = useState("");
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [configDetails, setConfigDetails] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  /* Recepción de datos en Formulario */
  const [formData, setFormData] = useState({
    nombre: "",
    phone: "",
    email: "",
    description: "",
    selectedCountryCode: "351",
    selectedDateTime: "",
  });
  const [adults, setAdults] = useState(0);
  const [teen, setTeen] = useState(0);    
  const [kids, setKids] = useState(0);
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const [kidsVisible, setKidsVisible] = useState("");
  const [teenVisible, setTeenVisible] = useState("");
  const [charsRemaining, setCharsRemaining] = useState(0);
  
  const handleEmployeeClick = async (employee) => {
    try {
      const selectedEmployeeId=employee.id;
      setSelectedEmployeeId(selectedEmployeeId);
    } catch (error) {
      console.error('Error fetching booking settings for employee:', error);
    }
  };
  const handleConfigSelect = async (content) => {
    setIsLoading(true);
    await setConfigDetails(content);
    setIsLoading(false);
  };

  const updateSelectedDateTime = (dateTime) => {
    setSelectedDateTime(dateTime); 
  };

  const handleIncrement = (field) => {
    if (field === "adults") {
      if (adults < configDetails.max_personas) {
        setAdults(adults + 1);
      }
    } else if (field === "teen") {
      setTeen(teen + 1);
    } else if (field === "kids") {
      setKids(kids + 1);
    }
  };
  const handleDecrement = (field) => {
    if (field === "adults" && adults > 0) {
      setAdults(adults - 1);
    } else if (field === "teen" && teen > 0) {
      setTeen(teen - 1);
    } else if (field === "kids" && kids > 0) {
      setKids(kids - 1);
    }
  };

  const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData({
    ...formData,
    [name]: value
  });
  };

  useEffect(() => {
    const fetchEmployeeSettings = async () => {
      try {
        if (selectedEmployeeId) {
          const settingsData = await getBookingSettings(selectedEmployeeId, token);
          const allBookings = await getAllBookings(selectedEmployeeId, token);
          setEmployeeSettings(settingsData);
          setKidsVisible(kids);
          setTeenVisible(teen);
        }
      } catch (error) {
        console.error('Erro ao obter configurações de reserva de funcionários:', error);
      }
    };
    fetchEmployeeSettings();
  }, [selectedEmployeeId, token]);
  useEffect(() => {
    if (user && user.id) {
      setCustomerUser(user.id);
    }
    const fetchData = async () => {
      try {
        let settingsData, allBookings;

        if (selectedEmployeeId) {
          settingsData = await getBookingSettings(selectedEmployeeId, token);
          allBookings = await getAllBookings(selectedEmployeeId, token);

        } else {
          settingsData = await getBookingSettings(id, token);
          allBookings = await getAllBookings(id, token);
        }

        const filteredBookings = allBookings.data
          .filter((booking) => booking.status_booking === 0 || booking.status_booking === 1)
          .map(({ date, status_booking }) => ({ date, status_booking }));
        setFilteredBookings(filteredBookings);
        setSettingsData(settingsData);
        const { kids, teen } = settingsData;
        setKidsVisible(kids);
        setTeenVisible(teen);
      } catch (error) {
        //console.error('Error al obtener la configuración de reserva:', error);
      }
    };
    
    fetchData();
  }, [formData.nombre, formData.phone, formData.email, adults, teen, kids, formData.description, user, token]);
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const employeesData = await getEmployeesList(token, id);
        
        setEmployees(employeesData.data);
      } catch (error) {
        console.error('Erro ao obter dados de team:', error);
      }
    };

    fetchEmployees();
  }, [token, id]);
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const phoneNumber = `${formData.selectedCountryCode}${formData.phone}`;
      const bookingData = {
        date: selectedDateTime,
        adults: adults,
        teen: teen,
        kids: kids,
        nombre: formData.nombre,
        phone: phoneNumber,
        email: formData.email,
        description: formData.description,
        customer_user: selectedEmployeeId || id,
        status_booking: 1,
        booking_configuration: configDetails.id,
      };
      const response = await createBooking(bookingData, token);
      setFormSubmitted(true);
      setFormError(null);
    
      toast.success("nova reserva criada", {
        position: "top-center",
        bodyClassName: "text-sm",
        autoClose: 1500,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
    } catch (error) {
      toast.error("Erro ao criar reserva", {
        position: "top-center",
        bodyClassName: "text-sm",
        autoClose: 1500,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
      setFormSubmitted(false);
      setFormError(error);
      console.error('Erro ao criar reserva:', error);
    }
  };

  const ResumeSection = () => {
    return (
      <div className="bg-neutral-200 p-4 rounded text-sm max-w-md mx-auto mt-3">
        {/* Contenido de la sección de reservas */}
        <h2 className="text-sm font-bold mb-2">Resumo:</h2>
        {selectedDateTime && <p className="text-gray-800"><strong>Data:</strong> {selectedDateTime}</p>}
        <br></br>
        {adults > 0 && <p className="text-gray-800"><strong>Adultos:</strong> {adults}</p>}
        {teen > 0 && <p className="text-gray-800"><strong>Bebês:</strong> {teen}</p>}
        {kids > 0 && <p className="text-gray-800"><strong>Crianças:</strong> {kids}</p>}
        <br></br>
        {formData.nombre && <p className="text-gray-800"><strong>Nome:</strong> {formData.nombre}</p>}
        {formData.selectedCountryCode && formData.phone && <p className="text-gray-800"><strong>Telefone:</strong>{"+"}{`${formData.selectedCountryCode} ${formData.phone}`}</p>}
        {formData.email && <p className="text-gray-800"><strong>Email:</strong> {formData.email}</p>}
        <br></br>
        {formData.description && <p className="text-gray-800"><strong>Descrição:</strong> {formData.description}</p>}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 z-50  bg-black bg-opacity-50 flex items-center justify-center" >
    {/* Sección de Reservas */}
        <div className="h-full overflow-y-auto  max-w-2xl w-5/6 bg-neutral-300 p-2">
          <div className=" flex justify-between items-center mt-2 mb-3">
              <h2 className="font-medium text-base whitespace-nowrap overflow-hidden text-ellipsis">
                  Nova Reserva
              </h2>
              <button
                  className="cursor-pointer text-base transition"
                  onClick={onClose}>
                  <FaTimes />
              </button>
          </div>

        {!formSubmitted && ( 
        <>
        {/* Seleccionar USERS ADMIN */}
        <div className="bg-white p-2 rounded-md mb-2 border border-neutral-300 shadow-md">
            <span className="text-gray-700 text-sm">Usuários:</span>
            <div className="flex flex-wrap">
            {employees
              .filter(employee => employee.is_booking)
              .map(employee => (
                <div
                  key={employee.id}
                  onClick={() => {
                    setSelectedEmployee(employee);
                    handleEmployeeClick(employee); 
                  }}
                  className="bg-neutral-800 cursor-pointer text-white text-xs font-semibold py-1 px-2 rounded-full m-2"
                  style={{ width: '150px', display: 'flex', alignItems: 'center' }}
                >
                  <img src={`${IMAGE_DOMAIN}${employee.profile.image}`}  className="rounded-full h-7 w-7 mr-2" />
                  <p>{employee.username}</p>
                </div>
              ))
            }
            </div>

            {selectedEmployee && (
              <div className="mt-4">
                <p>ID: {selectedEmployee.id}</p>
                <p>Username: {selectedEmployee.username}</p>
              </div>
            )}  
        </div>
        {/* Seleccionar seleccionar bookingconf */}
        <div className="bg-white p-2 rounded-md mb-2 border border-neutral-300 shadow-md">
            <span className="text-gray-700 text-sm">Horários:</span>
            <BookingConfList  
              id={selectedEmployeeId} handleConfigSelect={handleConfigSelect}
              />
        </div>
        {isLoading && (
          <div className="text-center mt-4">
            Carregando
          </div>
        )}
        {configDetails && !isLoading &&(
          <form className="mx-auto mt-1" onSubmit={handleSubmit}>
            <div className="bg-white p-2 rounded-md mb-2 border border-neutral-300 shadow-md">
              <span className="text-gray-700 text-base text-center ">Serviço: <p className="text-lg font-semibold uppercase"> {configDetails.btn}</p></span>
            </div>
            {/* Seleccionar Fecha y Hora */}
            <div className="bg-white p-2 rounded-md mb-2 border border-neutral-300 shadow-md">
              <span className="text-gray-700 text-sm">Seleccionar Data:</span>
              <DateTimeButtons
                configDetails={configDetails}
                formError={formError}
                setFormError={setFormError}
                bookings={filteredBookings}
                setSelectedDateTime={updateSelectedDateTime}/>
            </div>
              
            <div className="bg-white p-2 rounded-md mb-2 border-2 border-neutral-300 shadow-md">
                {/* Número de Adultos */}
                <div className="flex justify-center mr-4">
                  <label className="block mb-1">
                    <span className="text-gray-700 text-sm">Adultos ({configDetails.max_personas} máx):</span>
                    <div className="flex items-center">
                      <div
                        onClick={() => handleDecrement('adults')}
                        className="bg-neutral-800 hover:bg-neutral-700 text-white text-base font-bold py-1 px-2 rounded-l">
                        -
                      </div>

                      <input
                        className="form-input mt-1 block w-full border-2 text-base py-2 text-center border-neutral-300"
                        name="adults"
                        min={0}
                        value={adults}
                        readOnly/>
                      <div
                        onClick={() => handleIncrement('adults')}
                        className="bg-neutral-800 hover:bg-neutral-700 text-white text-base font-bold py-1 px-2 rounded-r">
                        +
                      </div>
                    </div>
                  </label>
                </div>

              {/* Número de Adolescentes */}
              {configDetails.teen && (
              <div className="flex justify-center mr-4">
                  <label className="block mb-1">
                    <span className="text-gray-700 text-sm">Bebés:</span>
                    <div className="flex items-center">
                      <div
                        onClick={() => handleDecrement('teen')}
                        className="bg-neutral-800 hover:bg-neutral-700 text-white text-base font-bold py-1 px-2 rounded-l">
                        -
                      </div>
                      <input
                        className="form-input mt-1 block w-full border-2 text-base py-2 text-center border-neutral-300"
                        name="teen"
                        min={0}
                        value={teen}
                        readOnly/>
                      <div
                        onClick={() => handleIncrement('teen')}
                        className="bg-neutral-800 hover:bg-neutral-700 text-white text-base font-bold py-1 px-2 rounded-r">
                        +
                      </div>
                    </div>
                  </label>
                </div>
              )}

              {/* Número de Niños */}
              {configDetails.kids && (
              <div className="flex justify-center mr-4">
                <label className="block mb-1">
                  <span className="text-gray-700 text-sm">Niños:</span>
                  <div className="flex items-center">
                    <div
                      onClick={() => handleDecrement('kids')}
                      className="bg-neutral-800 hover:bg-neutral-700 text-white text-base font-bold py-1 px-2 rounded-l">
                      -
                    </div>

                    <input
                      className="form-input mt-1 block w-full border-2 text-base py-2 text-center border-neutral-300"
                      name="kids"
                      min={0}
                      value={kids}
                      readOnly/>
                    <div
                      onClick={() => handleIncrement('kids')}
                      className="bg-neutral-800 hover:bg-neutral-700 text-white text-base font-bold py-1 px-2 rounded-r">
                      +
                    </div>
                  </div>
                </label>
              </div>
              )}
            </div>

            <div className="bg-white p-2 rounded-md mb-4 border-2 border-neutral-300 shadow-md">
              {/* Nombre */}
              <label className="block  mt-3">
                <span className="text-gray-700 text-sm">Nome:</span>
                <input
                className="form-input mt-1 block w-full text-sm  border-2 border-neutral-300 py-2 rounded-lg"
                type="text"
                name="nombre"
                value={formData.nombre}
                maxLength={50}
                onChange={handleInputChange}/>
              </label>
              <div className="text-xs text-gray-500 mb-1">
                Caracteres restantes: {50 - formData.nombre.length}
              </div>
              

              {/* Teléfono*/} 
              <label className="block mr-4">
                <span className="text-gray-700 text-sm">Telefone:</span>
                <div className="flex items-center">
                {/* Campo de zona horaria */}
                <select
                  className="mt-1 border-2 border-neutral-300 w-2/6 rounded-lg text-xs py-2 bg-neutral-200"
                  name="countryCode"
                  value={formData.selectedCountryCode}
                  onChange={handleInputChange}>
                  <CountryCodeOptions />
                </select>
                {/* Campo de número de teléfono */}
                <input
                  className="form-input ml-2 mt-1 block w-5/6 border-2 text-sm py-2 border-neutral-300 rounded-lg"
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  maxLength={50}
                  onChange={handleInputChange}/>
                </div>
              </label>
              <div className="text-xs text-gray-500 mb-1">
                Caracteres restantes: {50 - formData.phone.length}
              </div>

              {/* Correo */}
              <label className="block ">
                <span className="text-gray-700 text-sm">Email:</span>
                <input
                  className="form-input mt-1 block w-full border-2 text-sm border-neutral-300 rounded-lg py-2"
                  type="email"
                  name="email"
                  maxLength={100}
                  value={formData.email}
                  onChange={handleInputChange}/>
              </label>
              <div className="text-xs text-gray-500 mb-1">
                Caracteres restantes: {100 - formData.email.length}
              </div>
              {/* Descripcion */}
              <label className="block ">
                <span className="text-gray-700 text-sm">Descrição:</span>
                <textarea
                  className="form-input mt-1 block w-full border-2 text-sm border-neutral-300 rounded-lg py-2 p-3"
                  placeholder="Insira uma descrição"
                  name="description"
                  maxLength={255}
                  value={formData.description}
                  onChange={handleInputChange}/>
              </label>
              <div className="text-xs text-gray-500 mb-1">
                Caracteres restantes: {255 - formData.description.length}
              </div>
              <button
                className={`w-full text-white text-sm font-bold py-2 px-4 rounded ${formError !== null ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-400 hover:bg-blue-600'}`}
                type="submit"
                disabled={formError !== null}>
                Enviar
              </button>
            </div>
          </form>
        )}
        </>
        )}
        {formSubmitted && (
         <ResumeSection />
        )}
        </div>
    </div>
  );
};
export { BookingFormAdmin };