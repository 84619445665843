export const UserRolesEnum = {
  USER: 0,
  ADMIN: 1,
  SUPERADMIN: 2,
  SPONSOR: 3,
  BOOKING: 4,
};

export function objectToUserRole(user) {
  if (user.is_superuser) {
    return UserRolesEnum.SUPERADMIN;
  }
  if (user.is_admin) {
    return UserRolesEnum.ADMIN;
  }
  if (user.is_sponsor) {
    return UserRolesEnum.SPONSOR;
  }
  if (user.is_booking) {
    return UserRolesEnum.BOOKING;
  }
  
  return UserRolesEnum.USER;
}

export function objectToUserRoleIncorrectOnPurpose(user) {
  // ESTO CONTROLA A QUE INTERFAZ SE ENTRA AL PERSONIFICAR UN USUARIO
  // NO TIENE SENTIDO QUE UN SUPERUSUARIO ENTRE A LA INTERFAZ DE SUPERUSUARIO
  // POR LO TANTO SOLO RETORNA UserRolesEnum.USER;
  if (user.is_admin) {
    return UserRolesEnum.ADMIN;
  }
  if (user.is_sponsor){
    return UserRolesEnum.SPONSOR;
  }
  if (user.is_booking){
    return UserRolesEnum.BOOKING;
  }
  
  return UserRolesEnum.USER;
  
}

export function userRoleToString(userEnum) {
  switch (userEnum) {
    case UserRolesEnum.USER:
      return "Utilizador";
    case UserRolesEnum.ADMIN:
      return "Adminsitrador";
    case UserRolesEnum.SUPERADMIN:
      return "Superadministrador";
    case UserRolesEnum.SPONSOR:
      return "Associado";
    case UserRolesEnum.BOOKING:
      return "Reservas";
      default:
      return "desconhecido"
  }
}
