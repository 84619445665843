import virtualCard from "../../../assets/landing-page/decoration/plan-virtual2.png";
import basicCard from "../../../assets/landing-page/decoration/plan-simple-modelo.png";
import customCard from "../../../assets/landing-page/decoration/plan-medio-modelo.png";
import fullDesignCard from "../../../assets/landing-page/decoration/plan-avanzado-modelo.png";

import comboFullDesignCard from "../../../assets/landing-page/decoration/propuesta/plan-fulldesign-modelo.png";
import comboNFCCard from "../../../assets/landing-page/decoration/propuesta/plan-nfc-modelo.png";

export const productCodes = {
    DIGITAL: 5,
    BASIC: 2,
    CUSTOM: 3,
    FULL_DESIGN: 4,
    COMBO_FULL_DESIGN: 5,
    COMBO_NFC: 6,
}

export const defaultProducts = {
  [productCodes.DIGITAL]: {
    id: productCodes.DIGITAL,
    title: "Cartão Digital",
    image: virtualCard,
    description: "Desfruta de um cartão digital",
    price: 215,
  },
  [productCodes.BASIC]: {
    id: productCodes.BASIC,
    title: "Cartão Básico",
    image: basicCard,
    description: "Conecta-te com a nossa tecnologia",
    price: 185,
  },
  [productCodes.CUSTOM]: {
    id: productCodes.CUSTOM,
    title: "Cartão Personalizado",
    image: customCard,
    description: "A tua marca, a nossa tecnologia",
    price: 245,
  },
  [productCodes.FULL_DESIGN]: {
    id: productCodes.FULL_DESIGN,
    title: "Cartão Design Completo",
    image: fullDesignCard,
    description: "Controlo total sobre o teu cartão e a tua marca",
    price: 295,
  },
  /* [productCodes.COMBO_FULL_DESIGN]: {
    id: productCodes.COMBO_FULL_DESIGN,
    title: "Pack Full Design",
    image: comboFullDesignCard,
    description: "Personaliza tu tarjeta",
    price: 260,
  },
  [productCodes.COMBO_NFC]: {
    id: productCodes.COMBO_NFC,
    title: "Pack NFC",
    image: comboNFCCard,
    description: "TAG + NFC",
    price: 310,
  }, */
};
