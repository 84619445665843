import React, { useState, useEffect, useRef } from "react";
import { getUserDiscounts } from "../../admin_super/Api/discount.api";
import { useSelector } from "react-redux";
import { FaShare, FaCopy } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
function CuponesUsuario({ userID }) {
  const token = useSelector((state) => state.login.token);
  const [userDiscounts, setUserDiscounts] = useState([]);
  const inputRefs = useRef([]);

  const handleCopy = (index) => {
    const input = inputRefs.current[index];

    const tempTextarea = document.createElement("textarea");

    tempTextarea.value = `https://www.epadigital.pt/#/buy?id=1&cupon=${input.value}`;
    // Añadir el elemento de texto al DOM
    document.body.appendChild(tempTextarea);
    // Seleccionar el contenido del elemento de texto
    tempTextarea.select();
    // Ejecutar el comando de copia al portapapeles
    document.execCommand("copy");
    // Eliminar el elemento de texto temporal del DOM
    document.body.removeChild(tempTextarea);

    // Desseleccionar el input original
    input.blur();

    // Mostrar notificación tipo toast
    toast.success("Cupão copiado com sucesso!", {
      position: "bottom-right",
      autoClose: 2000, // Duración en milisegundos
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  useEffect(() => {
    const fetchUserDiscounts = async () => {
      try {
        const response = await getUserDiscounts(token, userID);
        setUserDiscounts(response.data);
      } catch (error) {
        console.error("Erro ao obter cupões do utilizador:", error);
      }
    };

    fetchUserDiscounts();
  }, [token, userID]);

  return (
    <div>
      <ul>
        {userDiscounts?.length === 0 && <p className="text-sm">Não tens nenhum cupão ativo</p>}
        {userDiscounts
        .filter(discount => discount.status? 't':'f' === 't') // Filtrar descuentos con status igual a 't'
        .map((discount, index) => (

          
          <li key={discount.id}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
              
              <input
                type="text"
                className="py-1 mr-2 px-2 flex flex-grow rounded-md text-sm border outline-none border-stone-300 text-stone-600"
                ref={(el) => (inputRefs.current[index] = el)}
                value={discount.verification_code}
                defaultValue={discount.verification_code}
                readOnly
                // style={{ flex: '1', marginRight: '8px', padding: '8px', border: '1px solid #ccc' }}
              ></input>
              <button
                onClick={() => handleCopy(index)}
                style={{ padding: "8px", cursor: "pointer", backgroundColor: "#007BFF", color: "#fff", border: "none" }}
              >
                <FaCopy />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export { CuponesUsuario };
