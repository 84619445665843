import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import AvatarEditor from "react-avatar-editor";

import { compressAvatarImageFromCanvas } from "../../../utils/uploadImages";
import OverlayModal from "../../../components/Modals/OverlayModal";
import LoadingSpinnerV2 from "../../../components/LoadingSpinnerV2";
import { updateAvatarPhoto } from "../../admin/Api/UserServices";

const EditBackgroundModal = (props) => {
  const token = useSelector((state) => state.login.token);

  const { setShowModal, imageSource, setProfileData, profileData } = props;

  const [zoom, setZoom] = useState(1);
  const avatarEditorRef = useRef(null);

  const [loading, setLoading] = useState(false);

  function handleZoom(e) {
    const zoomInput = parseFloat(e.target.value);
    setZoom(zoomInput);
  }

  function closeModal() {
    setShowModal(false);
  }

  async function handleEditAvatarImage(e) {
    e.preventDefault();

    setLoading(true);
    const canvas = avatarEditorRef.current;
    await canvas.getImage().toBlob(async (imageBlob) => {
      const compressedImage = await compressAvatarImageFromCanvas(imageBlob);

      updateAvatarPhoto(compressedImage, token, "background", profileData.customer_user)
        .then((response) => {
          updateBackgroundUI(response.data.background);
          closeModal();
        })
        .catch((error) => {
          console.log(error);
          window.alert(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }

  function updateBackgroundUI(image) {
    setProfileData({ ...profileData, background: image });
  }

  return (
    <>
      <OverlayModal onClick={closeModal} />

      <div
        className={`fixed right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 px-7 pt-4 pb-4 z-20 border rounded-md border-stone-300 bg-white shadow-lg`}
      >
        <p className="font-medium"> {`Editar foto de fondo`} </p>
        <AvatarEditor
          className="mt-2 rounded-md"
          image={imageSource}
          ref={avatarEditorRef}
          scale={zoom}
          width={350}
          height={200}
          border={20}
          disableBoundaryChecks={true}
          color={[150, 150, 150, 0.5]}
        />
        <div className="mt-4 flex justify-center">
          <input
            className="rounded-lg w-full accent-stone-800"
            name="zoom"
            type="range"
            onChange={handleZoom}
            min="0.2"
            max="3"
            step="0.01"
            defaultValue="1"
          />
        </div>

        <div className="mt-4 flex justify-center sm:flex-row gap-2">
          <button
            type="button"
            onClick={closeModal}
            className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
          >
            Cancelar
          </button>
          {/* TODO: Refactorizar con LoadingButton.js */}
          <button
            type="submit"
            onClick={handleEditAvatarImage}
            className="px-6 py-1 relative font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
          >
            <LoadingSpinnerV2
              className={`${
                loading ? "" : "invisible"
              } absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2`}
              size={20}
            />
            <p className={`${loading ? "invisible" : ""}`}> Adicionar </p>
          </button>
        </div>
      </div>
    </>
  );
};
export default EditBackgroundModal;
