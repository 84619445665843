import { useState } from "react";
import { useForm } from "react-hook-form";
import LoadingButton from "../../components/LoadingButton";
import { createAccountService } from "../admin/Api/UserServices";
import { useNavigate } from "react-router-dom";
import { ROUTER_INITIAL } from "../../config/Constant";
import { RegisterSuccessModal } from "./Components/RegisterSuccessModal";
import { Dialog } from "@radix-ui/react-dialog";

export function RegisterFormV2(props) {
  const { register, handleSubmit } = useForm();

  const [errorRegister, setErrorRegister] = useState("");

  const [openSuccess, setOpenSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const { hideRegisterForm } = props;

  function handleCloseSuccessRegister() {
    setOpenSuccess(false);
    hideRegisterForm();
  }

  async function handleRegister(data) {
    setLoading(true);
    createAccountService(data)
      .then((response) => {
        setErrorRegister("");
        setOpenSuccess(true);
      })
      .catch((error) => {
        if (error.code) {
          const code = error.code;
          if (code === "auth/email-already-in-use") {
            setErrorRegister(
              "Este correio eletrónico já se encontra em uso, tenta com outro"
            );
            return;
          }
          if (code === "auth/network-request-failed") {
            setErrorRegister(
              "Error al crear la cuenta, revisa tu conexión a internet"
            );
            return;
          }
          setErrorRegister(code);
          return;
        }
        setErrorRegister("Error al crear la cuenta, intente con otro correo");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <h1 className="mt-[8vh] mb-14 font-manrope text-2xl transition-font-size font-medium text-stone-700">
      Criar conta no Epa
      </h1>
      <div className="max-w-[26rem] flex flex-col w-full">
        <div className="max-w-[26rem] flex flex-col w-full">
          <form
            onSubmit={handleSubmit(handleRegister)}
            className="px-6 w-full flex flex-col items-center"
          >
            <div className="w-full text-input-group relative">
              <input
                id="email"
                className="login-input px-4 py-3 text-sm font-medium transition-font-size w-full rounded-lg text-input-login force-label-up"
                type="text"
                {...register("email")}
                required
              />
              <label
                htmlFor="email"
                className="pointer-events-none transition-all"
              >
                Correio eletrónico
              </label>
            </div>
            <div className="mt-8 w-full text-input-group relative">
              <input
                id="password"
                className="login-input px-4 py-3 text-sm font-medium transition-font-size w-full rounded-lg text-input-login force-label-up"
                type="password"
                {...register("password")}
                required
              />
              <label
                htmlFor="password"
                className="pointer-events-none transition-all"
              >
                Senha
              </label>
            </div>
            {errorRegister ? (
              <div className="px-2 py-1 mt-2 border-2 text-[13px] w-fit bg-red-200 text-red-800 border-red-300 00 ">
                {errorRegister}
              </div>
            ) : (
              <></>
            )}
            <LoadingButton
              isLoading={loading}
              className="px-4 py-3 mt-12 w-full relative flex justify-center transition-font-size rounded-xl font-semibold text-stone-100 bg-stone-950"
            >
              CRIAR CONTA
            </LoadingButton>

            <button
              onClick={hideRegisterForm}
              type="button"
              className="px-4 py-3 mt-3 w-full text-center font-semibold hover:underline"
            >
              VOLTAR AO INÍCIO DE SESSÃO
            </button>
          </form>
        </div>
        <Dialog open={openSuccess}>
          <RegisterSuccessModal onConfirm={handleCloseSuccessRegister} />
        </Dialog>
      </div>
    </>
  );
}
