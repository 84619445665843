import SoyYoName from "../../../assets/landing-page/brand/soyyo-name.png";

import LogoKetrawe from "../../../assets/landing-page/footer/logo-semillas-ketrawe.png";
import amchan from '../../../assets/landing-page/sponsors/amcham.png'
import cainco from '../../../assets/landing-page/sponsors/cainco.png'

import { scrollToById } from "../Services/Utils/scrollToById";


export function Footer() {
  return (
    <footer className="pt-12 pb-4 w-full text-stone-50 bg-stone-950">
      <div className="grid grid-cols-1 gap-y-8 md:grid-cols-5 ">
        <div className="col-span-2">
          <img src={SoyYoName} alt="SoyYo" className=" max-w-[8rem] mx-10" />
          <p className="px-8 mt-4 tracking-wide">
          Epa Digital é uma empresa focada na inovação e desenvolvimento 
          sustentável na Portugal.
          </p>
        </div>

        <div className="gap-3 px-8 w-fit flex-col flex">
          <p className="font-semibold mb-1">Navegar</p>
          <button onClick={scrollToById("start")} className="text-left">
          Início
          </button>
          <button onClick={scrollToById("benefits")} className="text-left">
          Benefícios
          </button>
          <button onClick={scrollToById("products")} className="text-left">
          Produtos
          </button>
          <button onClick={scrollToById("steps")} className="text-left">
          Obtém o teu cartão
          </button>
        </div>

        <div className="flex flex-col gap-3 px-8">
          <p className="font-semibold mb-1">Nossas redes</p>
          <a
            href="https://www.facebook.com/profile.php?id=100092543589746"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com/soyyo.digital/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=59169108791&text=Hola,+me+gustaría+saber+más+sobre+Soy+Yo+y+sus+tarjetas+digitales/"
            target="_blank"
            rel="noreferrer"
          >
            Whatsapp
          </a>
        </div>

        {/* <div className="flex flex-col gap-2 px-8">
          <p className="font-semibold mb-2">Apoyamos a</p>
          <a target="_blank" href="https://www.ketrawe.org/" rel="noreferrer">
            <img
              className="max-w-[10rem]"
              src={LogoKetrawe}
              alt="Logo Ketrawe"
            />
          </a>
        </div> */}
        {/* <div className="flex flex-col gap-2 px-8">
          <p className="font-semibold mb-2">Fazemos parte de:</p>

          <a target="_blank" href="https://www.cainco.org.bo/" rel="noreferrer">
            <img
              className="max-w-[6rem] max-h-[6rem] w-full h-auto"
              src={cainco}
              alt="Logo Cainco"
              style={{ maxWidth: '100%', maxHeight: '100%', transform: 'scale(0.9)' }}
            />
          </a>

          <a target="_blank" href="https://amchambolivia.com/amcham_es/" rel="noreferrer">
            <img
              className="max-w-[6rem] max-h-[6rem] w-full h-auto"
              src={amchan}
              alt="Logo Amcham"
              style={{ maxWidth: '100%', maxHeight: '100%', transform: 'scale(0.9)' }}
            />
          </a>
        </div> */}





      </div>

      <div className="mt-20">
        <p className="text-sm text-center">
          Copyright &copy; 2024 Epa Digital
        </p>
      </div>
    </footer>
  );
}
