import { DOMAIN } from "../../../config/Constant";

export async function getAllCompanies(token) {
  const response = await fetch(DOMAIN + "profile/allEmpresas?page=1", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  return response.json();
}
function buildQueryOrNone(name, value) {
  if (value) {
    return `${name}=${value}`;
  }
  return "";
}
export async function updateUserAccountData(data, token) {
  let extra = buildQueryOrNone("user_id", data.user_id ?? data.id);
  const response = await fetch(`${DOMAIN}auth/user?${extra}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(data),
  });

  const body = await response.json();
  if (!response.ok) {
    throw Error(body);
  }

  return body;
}

export async function createUser(data, token) {
  data.esEmpresa = data.tipo_usuario === "Empresa";
  data.correo = `${data.username}@soyyo.digital`;
  data.status = 1;

  const response = await fetch(DOMAIN + "auth/create-admin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(data),
  });
  const body = await response.json();
  if (!response.ok) throw Error(body.error ?? "No se pudo crear el usuario");
  return body;
}

export async function createManyUsers(data, token) {
  const response = await fetch(DOMAIN + "auth/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) throw Error("Error al crear usuario");
  return response.json();
}

export async function resetAccount(userId, token) {
  try {
    const response = await fetch(`${DOMAIN}auth/reset?user_id=${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({}),
    });

    const data = await response.json();

    if (response.ok) {
      console.log("Cuenta restablecida exitosamente");
    } else {
      console.error("Error al restablecer la cuenta:", data);
    }
  } catch (error) {
    console.error("Error al restablecer la cuenta:", error);
  }
}

export function deleteUser(userId, token) {
  fetch(`${DOMAIN}auth/user?user_id=${userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    }
  })
  .then(response => {
    if (response.ok) {
      console.log("Usuario eliminado exitosamente");
    } else {
      console.error("Error al eliminar usuario");
    }
  })
  .catch(error => {
    console.error("Error al eliminar usuario:", error);
  });
}

export async function deleteLicense(licenseId, token) {
  try {
    const response = await fetch(`${DOMAIN}adm/licenciasup/${licenseId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    });

    const data = await response.json();

    if (response.ok) {
      console.log("Licencia eliminada exitosamente");
    } else {
      console.error("Error al eliminar la licencia:", data);
    }
  } catch (error) {
    console.error("Error al eliminar la licencia:", error);
  }
}
