import React, { useState,useEffect } from "react";
import { useSelector } from 'react-redux';

import SoyYoLogo from "../../assets/images/soy-yo-nombre.png";
import SoyYoLogoHuella from "../../assets/images/soy-yo-logo.png";
import { SupportModal } from "../../components/Modals/SupportModal";
import {useUser, getAllBookings} from '../booking_page/Api/Booking';
import { 
  FaCog, 
  FaChartBar, 
  FaRegBuilding, 
  FaHeadset,
  FaMoneyBillWave, 
  FaCalendarAlt,
  FaStore,   
  FaStar,
  FaBell,
  FaShoppingBag,
  FaHome,
  FaCoins,
  FaBox,
} from "react-icons/fa";
import { RiFileList3Line } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";

export const OptionAdmin = {
  COMPANY: 0,
  ANALYTICS: 1,
  LICENCE: 2,
  SETTINGS: 3,
  BOOKING: 4,
  PAYMENTS: 5,
  EVENTS: 6,
  NOTIFICATIONS: 7,
  STORE: 8,
};
const SidebarButton = (props) => {
  const { icon, title, selected, onClick, user } = props;
  const [bookingNotificationsCount, setBookingNotificationsCount] = useState(0);
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);

  useEffect(() => {
    if (user && selected && title === "Reservas") {
      getAllBookings(user.id, token)
        .then((bookings) => {
          const count = bookings.data.filter((booking) => booking.status_booking === 0).length;
          setBookingNotificationsCount(count);
        })
        .catch((error) => {
          console.error("Error fetching bookings:", error);
        });
    }
  }, [user, selected, title, token]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    setBookingNotificationsCount(0);
  };
  
  return (
    <div
      onClick={handleClick}
      className={` ${
        selected
          ? "text-black bg-stone-50"
          : "text-stone-300 hover:bg-stone-800"
      } mx-0 sm:mx-4 rounded-none sm:rounded-lg flex-1 py-3 sm:py-[6px] sm:px-4 sm:pl-2 hover:cursor-pointer`}
    >
      <div className={`items-center flex flex-col sm:flex-row`}>
        {<div className="sm:ml-2"> {icon} </div>}
        <h2 className="mt-1 text-sm sm:mt-0 sm:ml-4 text-ellipsis whitespace-nowrap">
          {title}
        </h2>
      </div>
    </div>
  );
};

export const SidebarAdmin = (props) => {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const { selected, setSidebarOption, isBooking , userId, isSalesManager, user} = props;
  const { logout } = props;
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [bookingNotificationsCount, setBookingNotificationsCount] = useState(0);

  const [mainCollapseOpen, setMainCollapseOpen] = useState(true);
  const [salesCollapseOpen, setSalesCollapseOpen] = useState(false);
  const [storeCollapseOpen, setStoreCollapseOpen] = useState(true);
  const defaultIconSize = 16;
  useEffect(() => {
    if (user) {
      getAllBookings(user.id, token)
        .then((bookings) => {
          const count = bookings.data.filter((booking) => booking.status_booking === 0).length;
          setBookingNotificationsCount(count);
        })
        .catch((error) => {
          console.error("Error fetching bookings:", error);
        });
    }
  }, [user, token]);

  return (
    <div className="sidebar-shadow z-30 sm:z-[5] inset-0 top-auto shadow-lg fixed text-xs sm:w-56 sm:top-0 sm:right-auto bg-neutral-950 text-stone-50 overflow-y-auto">
      <div className="sm:flex sm:flex-col sm:h-full sm:justify-between">
        <div className="sm:mt-[4vh] mb-4">
          <img
            className="hidden max-w-[5rem] mx-auto sm:block"
            alt="soyyo icon"
            src={SoyYoLogo}
          />

<p className="mt-1 w-fit mx-auto text-sm font-semibold hidden sm:flex"> 
            Administrador 
          </p>

          <div className="flex flex-1 gap-0 sm:gap-2 sm:flex-none sm:mt-8 sm:flex-col ">

          <div
              onClick={() => setMainCollapseOpen(!mainCollapseOpen)}
              className={`cursor-pointer flex items-center ml-4 hover:bg-blue-800/20 mx-0 sm:mx-4 rounded-none sm:rounded-lg flex-1 py-3 sm:py-[6px] sm:px-4 sm:pl-2 hover:cursor-pointer ${mainCollapseOpen ? 'bg-blue-800/20' : ''}`}
            >
              <p className="mr-2 font-bold text-base">{mainCollapseOpen ? "-" : "+"}</p>
              <div className="flex items-center">
                <FaHome className="text-md sm:text-xl" />
                <h2 className="ml-2 text-sm sm:text-sm font-medium">Home</h2>
              </div>
            </div>
            {/* Botones dentro del collapse */}
            {mainCollapseOpen && (
              <>
                <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.COMPANY)}
              selected={selected === OptionAdmin.COMPANY}
              icon={<FaRegBuilding size={defaultIconSize} />}
              title="Equipa"
            />
            <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.ANALYTICS)}
              selected={selected === OptionAdmin.ANALYTICS}
              icon={<FaChartBar size={defaultIconSize} />}
              title="Métricas"
            />
            
            <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.LICENCE)}
              selected={selected === OptionAdmin.LICENCE}
              icon={<RiFileList3Line size={defaultIconSize} />}
              title="Licença"
            />

            <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.NOTIFICATIONS)}
              selected={selected === OptionAdmin.NOTIFICATIONS}
              icon={<FaBell size={defaultIconSize} />}
              title="Notificaciones"
            />  
            {user && isBooking &&(
              <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.BOOKING)}
              selected={selected === OptionAdmin.BOOKING}
              icon={
                <div className="relative">
                  <FaCalendarAlt size={defaultIconSize} />
                  {bookingNotificationsCount > 0 && (
          <div className="absolute top-0 right-0 -mt-1 -mr-1 h-4 w-4 bg-blue-500 rounded-full flex items-center justify-center text-white text-xs">
            {bookingNotificationsCount}
          </div>
        )}
                </div>
              }
              title="Reservas"
            />
            )}
              </>
            )}
            
            
            {isSalesManager && (
            <>

            <div
              onClick={() => setSalesCollapseOpen(!salesCollapseOpen)}
              className={`cursor-pointer flex items-center ml-4 hover:bg-orange-700/20 mx-0 sm:mx-4 rounded-none sm:rounded-lg flex-1 py-3 sm:py-[6px] sm:px-4 sm:pl-2 hover:cursor-pointer ${salesCollapseOpen ? 'bg-orange-700/20' : ''}`}

            >
              <p className="mr-2 font-bold text-base">{salesCollapseOpen ? "-" : "+"}</p>
              <div className="flex items-center">
                <FaCoins className="text-md sm:text-xl" />
                <h2 className="ml-2 text-sm sm:text-sm font-medium">Finanzas</h2>
              </div>
            </div>
            {/* Botones dentro del collapse */}
            {salesCollapseOpen && (
              <>
                <SidebarButton
                  onClick={() => setSidebarOption(OptionAdmin.PAYMENTS)}
                  selected={selected === OptionAdmin.PAYMENTS}
                  icon={<FaMoneyBillWave size={defaultIconSize} />}
                  title="Ventas"
                />  
              </>
            )}
          
         
            </>  
          )}




           {/*  <div
              onClick={() => setStoreCollapseOpen(!storeCollapseOpen)}
              className={`cursor-pointer flex items-center ml-4 hover:bg-purple-900/20 mx-0 sm:mx-4 rounded-none sm:rounded-lg flex-1 py-3 sm:py-[6px] sm:px-4 sm:pl-2 hover:cursor-pointer ${storeCollapseOpen ? 'bg-purple-900/20' : ''}`}
            >
              <p className="mr-2  font-bold text-base">{storeCollapseOpen ? "-" : "+"}</p>
              <div className="flex items-center">
                <FaBox className="text-md sm:text-xl" />
                <h2 className="ml-2 text-sm sm:text-sm font-medium">Módulos</h2>
              </div>

            </div>
            {storeCollapseOpen && (
              <>
                <SidebarButton
                  onClick={() => setSidebarOption(OptionAdmin.STORE)}
                  selected={selected === OptionAdmin.STORE}
                  icon={<FaShoppingBag size={defaultIconSize} />}
                  title="Store"
                />
                <SidebarButton
                  onClick={() => setSidebarOption(OptionAdmin.EVENTS)}
                  selected={selected === OptionAdmin.EVENTS}
                  icon={<FaStar size={defaultIconSize} />}
                  title="Eventos"
                /> 
              </>
            )} */}

              
            
          </div>
        </div>

        <div className="hidden relative sm:block">
          <img
            className="absolute -top-full left-1/2 rotate-12 -translate-x-1/2 opacity-10 w-16 -z-10"
            alt="_"
            src={SoyYoLogoHuella}
          ></img>
          <div className="mx-auto mb-4 w-3/4 h-[2px] rounded-md bg-stone-600"></div>
          <div className="mb-4">
          <SidebarButton
              onClick={() => setSidebarOption(OptionAdmin.SETTINGS)}
              selected={selected === OptionAdmin.SETTINGS}
              icon={<FaCog size={defaultIconSize} />}
              title="Opções"
            />
            <SidebarButton
              onClick={() => setShowSupportModal(true)}
              selected={false}
              icon={<FaHeadset size={defaultIconSize} />}
              title="Suporte"
            />
            <SupportModal
              setShowModal={setShowSupportModal}
              showModal={showSupportModal}
            />
            <SidebarButton
              onClick={() => logout()}
              selected={false}
              icon={<FiLogOut size={defaultIconSize} />}
              title="Terminar Sessão"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
