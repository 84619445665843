/* import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDbkLm-AW1Ai6Qd8IoCG59_BtMPoO3NxfE",
  authDomain: "soyyo-auth.firebaseapp.com",
  projectId: "soyyo-auth",
  storageBucket: "__BUCKET__",
  messagingSenderId: "___sender_id__",
  appId: "__appid__",
  measurementId: "optional",
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); */

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBL_ZshO-9H5armvM47SzfRy684gHrW0Dg",
  authDomain: "epadigital-auth-1ccf2.firebaseapp.com",
  projectId: "epadigital-auth",
  storageBucket: "epadigital-auth.appspot.com",
  messagingSenderId: "345479763979",
  appId: "1:345479763979:web:5b1cea8d0b3f1f85e34f0e",
  measurementId: "G-S2VYR0NELB"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);