import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { FiShare } from "react-icons/fi";
import LoadingOption from "../../components/LoadingOption";

import { DOMAIN, IMAGE_DOMAIN, LOCALHOST_DOMAIN } from "../../config/Constant";
import EditSocialLinkButton from "./EditCardComponents/EditSocialLinkButton";
import AddSocialMediaButton from "./EditCardComponents/AddSocialMediaButton";
import PreviewDigitalCard from "./PreviewDigitalCard";
import CardContainer from "../../components/CardContainer";

import {getBookingSettings, updateBookingSettings} from '../booking_page/Api/Booking'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useForm } from "react-hook-form";

import AddCustomLinkButton from "./EditCardComponents/AddCustomLinkButton";
import EditAvatarButton from "./EditCardComponents/EditAvatarButton";
import EditBackgroundButton from "./EditCardComponents/EditBackgroundButton";
import EditColorButton from "./EditCardComponents/EditColorToolbar";

import {
  createCustomSocialMedia,
  updateAvatarPhoto,
  updateProfile,
} from "../admin/Api/UserServices";
import { LinkCreatorOptions } from "./EditCardComponents/LinkCreationOptions";


async function fetchData(endpoint, token) {
  const response = await fetch(DOMAIN + endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
  return response;
}

function EditCard(props) {
  const token = useSelector((state) => state.login.token);
  const [isLoading, setIsLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [customSocialMedia, setCustomSocialMedia] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const { userData, userPersonified } = props;

  const [arrayLength, setArrayLength] = useState(0);
  const [hasContactCustomMedia, setHasContactCustomMedia] = useState(false);
  const [hasStoreCustomMedia, setHasStoreCustomMedia] = useState(false);
  const [hasMainLinkCustomMedia, setHasMainLinkCustomMedia] = useState(false);

  const [openPreview, setOpenPreview] = useState(false);
  const [statusBooking, setStatusBooking] = useState(0);
  let id = userData.username;
  if (userPersonified) {
    id = userPersonified.username;
  }

  const getProfile = async () => {
    const response = await fetchData(`profile/userWithoutStatcis/${id}`, token);
    const profile = await response.json();
    if (!response.ok) throw Error(response);
    return profile;
  };

  const loadProfileData = () => {
    getProfile()
      .then((response) => {
        const sortedCustomSocialMedia = response.data.custom_social_media.sort(
          (a, b) => a.order - b.order
        );
        setProfileData(response.data.profile);
        setCustomSocialMedia(sortedCustomSocialMedia);
  
        // Verificar si hay un customsocialmedia de tipo contact
        const hasContact = sortedCustomSocialMedia.some(
          (media) => media.type === "contact"
        );
        const hasStore = sortedCustomSocialMedia.some(
          (media) => media.type === "store"
        );
        const hasMainLink = sortedCustomSocialMedia.some(
          (media) => media.type === "mainLink"
        );
        
        setHasContactCustomMedia(hasContact);
        setHasStoreCustomMedia(hasStore);
        setHasMainLinkCustomMedia(hasMainLink);
  
        // Set the array length
        setArrayLength(sortedCustomSocialMedia.length);
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(
          `Ocorreu um erro ao obter os dados. Status: ${error.status}`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadProfileData();
    
    const fetchBookingSettings = async () => {
      try {
        const bookingSettings = await getBookingSettings(userData.id, token);
        const statusConf = bookingSettings.data?.configuration?.status_conf;
  
        if (statusConf !== undefined) {
          setStatusBooking(statusConf);
        } else {
          console.error("A propriedade status_conf não está presente na resposta de bookingSettings:", bookingSettings);
        }
      } catch (error) {
        console.error("Error fetching booking settings:", error);
      }
    };
  
    fetchBookingSettings();
  }, [token]);
  function loadedSuccessfully() {
    return errorMessage.length === 0;
  }

  function handleOpenPreview() {
    setOpenPreview(!openPreview);
  }

  return (
    <div className="w-full font-montserrat bg-[rgb(240,240,240)]">
      {isLoading ? (
        <div className="w-full h-screen">
          <LoadingOption />
        </div>
      ) : (
        <>
          {loadedSuccessfully() ? (
            <div className="flex flex-col">
              <div className="mt-8 px-8 flex justify-between">
                <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
                  Editar perfil
                </h2>
                <button
                  onClick={handleOpenPreview}
                  className="fixed top-8 z-[5] right-6 lg:static px-2 py-1 font-semibold rounded-md bg-stone-900 text-stone-100"
                >
                  Pré-visualizar
                </button>
              </div>

              <EditCardBody
                setCustomSocialMedia={setCustomSocialMedia}
                customSocialMedia={customSocialMedia}
                setProfileData={setProfileData}
                profileData={profileData}
                openPreview={openPreview}
                userData={userData}
                statusBooking={statusBooking}
                setStatusBooking={setStatusBooking}
                arrayLength={arrayLength}
                hasContactCustomMedia={hasContactCustomMedia}
                setHasContactCustomMedia={setHasContactCustomMedia}
                hasStoreCustomMedia={hasStoreCustomMedia}
                setHasStoreCustomMedia={setHasStoreCustomMedia}
                hasMainLinkCustomMedia={hasMainLinkCustomMedia}
                setHasMainLinkCustomMedia={setHasMainLinkCustomMedia}
              />
            </div>
          ) : (
            <>
              <div>
                <p> Error: </p>
                <p> {errorMessage} </p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export function EditCardBody(props) {
  const { 
    userData, 
    profileData, 
    customSocialMedia, 
    openPreview, 
    statusBooking, 
    arrayLength,
    setProfileData, 
    setCustomSocialMedia, 
    hasContactCustomMedia, 
    setHasContactCustomMedia,
    hasStoreCustomMedia,
    setHasStoreCustomMedia,
    hasMainLinkCustomMedia,
    setHasMainLinkCustomMedia } = props;


  const handleMoveLinkUp = (index) => {
    const updatedLinks = [...customSocialMedia];
    if (index > 0) {
      const temp = updatedLinks[index];
      updatedLinks[index] = updatedLinks[index - 1];
      updatedLinks[index - 1] = temp;
      setCustomSocialMedia(updatedLinks);
    }
    updatedLinks.forEach((link, i) => {
      link.order = i + 1; // Puedes ajustar esto según tus necesidades
    });

    sendUpdatedOrderToBackend(updatedLinks);
  };
  
  const handleMoveLinkDown = (index) => {
    const updatedLinks = [...customSocialMedia];
    if (index < updatedLinks.length - 1) {
      const temp = updatedLinks[index];
      updatedLinks[index] = updatedLinks[index + 1];
      updatedLinks[index + 1] = temp;
      setCustomSocialMedia(updatedLinks);
    }
   // Actualiza el campo de orden en cada enlace
   updatedLinks.forEach((link, i) => {
    link.order = i + 1; // Puedes ajustar esto según tus necesidades
  });

  sendUpdatedOrderToBackend(updatedLinks);
  };

  let { onSubmitAboutMe, onSocialMediaCreateForAll, editAvatarOrBackground } =
    props;
    onSocialMediaCreateForAll =
    onSocialMediaCreateForAll ?? createCustomSocialMedia;
    const sendUpdatedOrderToBackend = (updatedLinks) => {
  const orderData = {
    ids: {},
  };

  updatedLinks.forEach((link, index) => {
    link.order = index + 2; // Ajuste para comenzar desde 2 en lugar de 1
    orderData.ids[index + 2] = link.id;
  });

  fetch(DOMAIN + "profile/custom_social_media_order", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(orderData),
  })
    .then((response) => {
      if (!response.ok) {
        console.error("Erro ao enviar a nova ordem ao backend:", response.status);
      } else {
      }
    })
    .catch((error) => {
      console.error("Erro durante o pedido:", error);
    });
};

  const token = useSelector((state) => state.login.token);

  const { register, handleSubmit, setValue, formState, reset } = useForm({
    defaultValues: profileData,
  });

  function handleReloadFormData(newProfileData) {
    reset({ ...(newProfileData ?? profileData) });
  }

  function handleUpdatePreviewText(event, propertyName) {
    const value = event.target.value;
    const object = { [propertyName]: value };
    setProfileData({
      ...profileData,
      ...object,
    });
  }

  function handleUpdateColorUI(event, color) {
    let value = "";
    if (event !== undefined) {
      value = event.target.value;
    } else {
      value = color;
      setValue("color", value, { shouldDirty: true });
    }
    const object = { color: value };

    setProfileData({
      ...profileData,
      ...object,
    });
  }

  function formHasBeenEdited() {
    return formState.isDirty;
  }

  function handleSubmitAboutMe(data) {
    const { public_name, career, description, color } = data;

    const dataUser = {
      user_id: userData?.id,
      public_name: public_name,
      career: career,
      description: description,
      color: color,
    };
    let validOnSubmit = onSubmitAboutMe ?? updateProfile;

    validOnSubmit(dataUser, token)
      .then((req) => {
        handleReloadFormData(data);
      })
      .catch((error) => {
        window.alert("Error al actualizar información de la tarjeta");
      });
  }

  function setCustomSocialMediaIndexUI(index) {
    return (socialMedia) => {
      customSocialMedia[index] = socialMedia;
      setCustomSocialMedia([...customSocialMedia]);
    };
  }

  function deleteCustomSocialMediaIndexUI(index) {
    return () => {
      customSocialMedia.splice(index, 1);
      setCustomSocialMedia([...customSocialMedia]);
    };
  }

  function addCustomSocialMediaUI(link) {
    const maxOrder = Math.max(...customSocialMedia.map(link => link.order), 0);
    const newOrder = maxOrder + 1;
  
    const newLink = {
      ...link,
      order: newOrder,
    };
  
    setCustomSocialMedia([...customSocialMedia, newLink]);
  
    sendUpdatedOrderToBackend([...customSocialMedia, newLink]);
  }

  return (
    <div className="mt-4 flex bg-[rgb(240,240,240)]">
      <div className="flex flex-col flex-1  lg:max-w-4xl px-6  sm:pr-6 gap-6">
        <CardContainer className="px-6 py-4 flex flex-col shadow-md">
          <p className="font-medium"> Sobre ti </p>
          <form
            className="flex flex-col text-sm"
            onSubmit={handleSubmit(handleSubmitAboutMe)}
          >
            <label
              className="mt-2 text-stone-800 font-medium"
              htmlFor="public_name"
            >
              Nome
            </label>
            <input
              className="px-4 py-2 border outline-none w-full"
              type="text"
              {...register("public_name", {
                onChange: (event) =>
                  handleUpdatePreviewText(event, "public_name"),
              })}
              id="public_name"
              name="public_name"
              placeholder="Nombre/Empresa"
              maxLength={60}
              required
            />
            <div className="text-xs text-gray-500 mt-1">
              Limit: {60 - profileData.public_name.length} {/* Muestra el contador de caracteres restantes */}
            </div>
            <label className="mt-4 text-stone-800 font-medium" htmlFor="career">
            Cargo/Ocupação
            </label>
            <input
              className="px-4 py-2 border outline-none w-full"
              type="text"
              {...register("career", {
                onChange: (event) => handleUpdatePreviewText(event, "career"),
              })}
              id="career"
              name="career"
              placeholder="Cargo/Ocupação"
              maxLength={50}
            />
            <div className="text-xs text-gray-500 mt-1">
              Limit: {50 - profileData.career.length} {/* Muestra el contador de caracteres restantes */}
            </div>
            <label
              className="mt-4 text-stone-800 font-medium"
              htmlFor="description"
            >
              Descrição
            </label>
            <textarea
              className="px-4 py-2 border outline-none w-full resize-none"
              {...register("description", {
                onChange: (event) =>
                  handleUpdatePreviewText(event, "description"),
              })}
              name="description"
              id="description"
              rows={2}
              maxLength={100}
              placeholder="Descrição sobre ti"
            ></textarea>
            <div className="text-xs text-gray-500 mt-1">
              Limit: {100 - profileData.description.length} {/* Muestra el contador de caracteres restantes */}
            </div>
            <p className="mt-4 font-medium"> Color </p>
            <div className="mt-2">
              <EditColorButton
                registerForm={register}
                handleUpdateColorUI={handleUpdateColorUI}
                cardColor={profileData.color}
              />
            </div>
            <button
              type="submit"
              disabled={!formHasBeenEdited()}
              className="mt-4 px-8 py-1 font-medium w-fit rounded-md text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
            >
              Atualizar
            </button>
          </form>
        </CardContainer>
        <CardContainer className="px-6 py-4 shadow-md">
          <p className="font-medium"> Adicionar imagens </p>
          <div className="mt-3 flex flex-col gap-4 sm:gap-16 sm:flex-row">
            <div>
              <p className="text-sm font-medium"> Foto de perfil </p>
              <EditAvatarButton
                profileData={profileData}
                setProfileData={setProfileData}
                image={`${IMAGE_DOMAIN}${profileData.image}`}
                editAvatarOrBackground={editAvatarOrBackground}
              />
            </div>
            <div className="mr-2">
              <p className="text-sm font-medium">Fundo do cartão</p>
              <EditBackgroundButton
                profileData={profileData}
                setProfileData={setProfileData}
                image={`${IMAGE_DOMAIN}${profileData.background}`}
                editAvatarOrBackground={editAvatarOrBackground}
              />
            </div>
          </div>
        </CardContainer>

        <CardContainer className="mb-6 px-6 py-4 shadow-md">
          <p className="font-medium"> Adiciona os teus links </p>
          <div className="mt-4 gap-3 flex flex-col">
            {customSocialMedia.map((link, index) => {
              if (link.type === "store" && !userData.is_ecommerce) {
                return null; // Ocultar este elemento si userData.is_ecommerce es false
              }

              return (
                <EditSocialLinkButton
                  key={index}
                  index={index}
                  link={link}
                  deleteSocialMedia={deleteCustomSocialMediaIndexUI(index)}
                  setSocialMedia={setCustomSocialMediaIndexUI(index)}
                  moveLinkUp={handleMoveLinkUp} // Asegúrate de tener esta función definida
                  moveLinkDown={handleMoveLinkDown} // Asegúrate de tener esta función definida
                  hasContactCustomMedia={hasContactCustomMedia}
                  setHasContactCustomMedia={setHasContactCustomMedia}
                  hasStoreCustomMedia={hasStoreCustomMedia}
                  setHasStoreCustomMedia={setHasStoreCustomMedia}
                  hasMainLinkCustomMedia={hasMainLinkCustomMedia}
                  setHasMainLinkCustomMedia={setHasMainLinkCustomMedia}
                />
              );
            })}
          </div>
          <AddCustomLinkButton
            onSubmit={(data, token) => {
              data.user_id = profileData.customer_user;
              return onSocialMediaCreateForAll(data, token);
            }}
            addSocialMedia={addCustomSocialMediaUI}
            arrayLength ={arrayLength }
          />
          <LinkCreatorOptions
            onCompleteForAll={addCustomSocialMediaUI}
            onSubmitForAll={(data, token) => {
              data.user_id = profileData.customer_user;
              return onSocialMediaCreateForAll(data, token);
            }}
            hasContactCustomMedia={hasContactCustomMedia}
            setHasContactCustomMedia={setHasContactCustomMedia}
            userId={profileData.customer_user}
            hasStoreCustomMedia={hasStoreCustomMedia}
    setHasStoreCustomMedia={setHasStoreCustomMedia}
    is_ecommerce={userData.is_ecommerce}
    hasMainLinkCustomMedia={hasMainLinkCustomMedia}
    setHasMainLinkCustomMedia={setHasMainLinkCustomMedia}
          />
        </CardContainer>
        
      </div>
      <div
        className={`${
          openPreview
            ? "inset-0 left-0 bottom-16 sm:bottom-0 pb-8 sm:left-56 top-0 fixed overflow-y-auto bg-[rgb(240,240,240)] pr-4"
            : "hidden"
        } pr-10 pl-4 mx-auto lg:block`}
      >
        <div
          className={`${
            openPreview
              ? "mx-auto mt-20 scale-125 origin-top"
              : "pt-8 top-1/2 sticky -translate-y-1/2"
          } w-64`}
        >
          <PreviewDigitalCard
            profileData={profileData}
            userData={userData}
            customSocialMedia={filterVisibleSocialMedia(customSocialMedia)}
            statusBooking={statusBooking}
          ></PreviewDigitalCard>
          <div className="mt-2 text-blue-500">
            <div className="w-fit flex mx-auto">
              <a
                className="font-medium hover:underline"
                target="_blank"
                href={`https://www.epadigital.pt/u/#/${userData.username}`}
                rel="noreferrer"
              >
                <div className="flex w-fit items-center">
                  <FiShare className="mr-2" />
                  Abrir Cartão
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCard;


function filterVisibleSocialMedia(links) {
  return links.filter((link) => link.is_visible === true);
}
