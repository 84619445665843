import { useEffect, useRef, useState } from "react";
import {
  RequestMercadoPago,
  consultTransactionById,
  requestPaymentMethod,
} from "../../admin/Api/UserServices";
import { useSelector } from "react-redux";
import LoadingSpinnerV2 from "../../../components/LoadingSpinnerV2";
import { toast } from "react-toastify";
import { FaLink } from 'react-icons/fa';

const SCRUM_PAYMENT_STATUS = {
  0: "AUTORIZADA",
  1: "RECHAZADA",
  2: "FRAUDE",
  3: "TECNICO",
  4: "INSUFICIENTE",
  5: "REJECT",
  6: "HONOR",
  7: "RETENIDO",
  8: "PENDIENTE",
};

export function PaymentSCRUM(props) {
  const token = useSelector((state) => state.login.token);

  const [iframeSCRUM, setIframeSCRUM] = useState(undefined);

  const [iframeLoading, setIframeLoading] = useState(true);
  const [transactionId, setTransactionId] = useState(null);
  const [solicitud_pago, setSolicitudPago] = useState("");


  const { onError, products, paymentInfo, verificationCode, discount_id } = props;
  async function requestPayment() { 
    paymentInfo.verificationCode = verificationCode;
    paymentInfo.discount_id= discount_id;
    setSolicitudPago("")
    setIframeLoading(true)
    setIframeSCRUM(undefined)
    RequestMercadoPago(paymentInfo, products, token)
      .then((response) => {
        const solicitud_pago=response.data.init_point;
        setIframeSCRUM(response.data.init_point);
        setSolicitudPago(solicitud_pago)
        setTransactionId(response.data.id);
      })
      .catch((error) => {
        toast.error("Ocorreu um erro inesperado, tenta novamente mais tarde.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        if(onError) {
          onError()
        }
      })
      .finally(() => {});
  }
  function handleIframeLoaded() {
    setIframeLoading(false);
  }
  
  const copyToClipboard = () => {
    // Crear un elemento de input temporal
    const input = document.createElement("input");
    input.value = solicitud_pago;

    // Agregar el elemento al DOM
    document.body.appendChild(input);

    // Seleccionar el contenido del input
    input.select();
    input.setSelectionRange(0, 99999); // Para dispositivos móviles

    // Copiar al portapapeles
    document.execCommand("copy");

    // Eliminar el elemento del DOM
    document.body.removeChild(input);

    // Mostrar un mensaje de éxito
    toast.success("Link copiado para a área de transferência", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const shareLink = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Share Link',
        text: 'Confira este link!',
        url: solicitud_pago,
      })
        .then(() => console.log('Link compartilhado'))
        .catch((error) => console.error('Erro de compartilhamento de link:', error));
    } else {
      // Fallback para navegadores que no admiten Web Share API
      copyToClipboard();
      // Aquí podrías mostrar alguna alternativa, como un mensaje para copiar el enlace manualmente.
    }
  };

  useEffect(() => {
    requestPayment();
  }, [verificationCode, discount_id]);
  return (
<div className="flex justify-center mb-12">
      <div className="w-full md:w-[400px] lg:w-[800px] grid place-items-center">

        {iframeLoading ? <LoadingSpinnerV2 size={40} /> : <></>}

        {iframeSCRUM ? (
           <>
           <div className="flex flex-col items-center">
          <iframe
            onLoad={handleIframeLoaded}
            className="w-full md:w-[400px] lg:w-[800px] h-[300px] md:h-[500px] lg:h-[600px] overflow-hidden"
            title="Scrum pay - Pago"
            src={iframeSCRUM}
          ></iframe>
          <button
                onClick={shareLink}
                className="bg-purple-800 p-2 rounded-md px-5 text-white mb-12 flex items-center"
              >
                <FaLink className="mr-2" />
                Share Link
              </button>
        </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}


