import { OrderSummaryCollapsible } from "./OrderSummaryCollapsible";
import getDiscountByVerificationCode from "../../admin_super/Api/discount.api";
import { CuponTypes } from "../Data/CuponTypes";

export function OrderSummary(props) {
  const { products, deliveryCost, discountRate, discountType } = props;

  const totalProductos = computeSubtotalProducts(products);
  const delivery = deliveryCost ?? 0;

  let descuento = 0;
  if (discountType === CuponTypes.Percentage) {
    descuento = (totalProductos * discountRate) / 100;
  }
  if (discountType === CuponTypes.Price) {
    descuento = discountRate;
  }
  const total = totalProductos + delivery
  const totalApagar = totalProductos - descuento + delivery;
  return (
    <div className="px-6 py-3 bg-stone-100">
      <div className="mt-3 mb-3">
        <OrderSummaryCollapsible>
          <>
            <div className="gap-1 mt-2 flex flex-col text-stone-800">
              {products.map((product, index) => (
                <div key={index} className="text-sm flex justify-between">
                  <p>
                    {product.title} &nbsp;
                    <strong className="font-medium">({product.quantity})</strong>
                  </p>
                  <p>€ {(product.price * product.quantity).toFixed(2)}</p>
                </div>
              ))}
            </div>
          </>
        </OrderSummaryCollapsible>
      </div>

      <div className="gap-y-2 w-full text-sm sm:text-base  grid grid-cols-2">
        <h3 className="text-stone-800">Subtotal</h3>
        <p className="text-right text-stone-800 whitespace-nowrap">€ {totalProductos.toFixed(2)}</p>
        <h3 className="text-stone-800">Envio</h3>
        <p className="text-right text-stone-800 whitespace-nowrap">€ {delivery.toFixed(2)}</p>
        <h3 className="font-bold text-stone-800">Total</h3>
        <p className="text-right font-semibold whitespace-nowrap">€ {total.toFixed(2)}</p>
        <h3 className="text-green-700 ">
          {`Descuento${discountType === CuponTypes.Percentage ? ` de: ${discountRate} %` : ``}
        ${discountType === CuponTypes.Price ? ` de: ${discountRate} €` : ``}
        `}
        </h3>
        <p className="text-right text-green-700 whitespace-nowrap">€ {descuento}</p>
        <h3 className="font-bold text-stone-800">Total a Pagar</h3>
        <p className="text-right font-semibold whitespace-nowrap">
          € {totalApagar <= 0 ? "0.00" : totalApagar.toFixed(2)}
        </p>
      </div>
    </div>
  );
}

export function computeSubtotalProducts(products) {
  return products.reduce((sum, product) => {
    return sum + product.price * product.quantity;
  }, 0);
}
