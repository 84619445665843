import { useState } from "react";

import {
  DialogPortal,
  DialogOverlay,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "@radix-ui/react-dialog";
import {
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupItem,
} from "@radix-ui/react-radio-group";
import { AiOutlineClose, AiOutlineBarChart } from "react-icons/ai";

import { getAllCompanyAreas } from "../../../utils/analyticsUtils";
import "./adminComponentStyles.css";
import { generateRandomKey } from "../../../utils/generateRandomKey";

function EmployeeRadioButton(props) {
  const { employee } = props;
  return (
    <div className="flex relative items-center w-fit">
      <RadioGroupItem
        className="ml-2 rounded-full w-3 h-3 radio-item"
        value={employee.username}
        id={employee.username}
      >
        <RadioGroupIndicator className="flex items-center justify-center w-full h-full radio-indicator" />
      </RadioGroupItem>
      <label className="px-2 py-1" htmlFor={employee.username}>
        {employee.username}
      </label>
      <div className="radio-background rounded-lg absolute w-full h-full pointer-events-none"></div>
    </div>
  );
}

function AreaRadioButton(props) {
  const { area } = props;

  let areaTitle = area;
  if (!areaTitle) {
    areaTitle = "Não definido";
  }

  return (
    <div className="flex relative items-center w-fit">
      <RadioGroupItem
        className="ml-2 rounded-full w-3 h-3 radio-item"
        value={area}
        id={areaTitle}
      >
        <RadioGroupIndicator className="flex items-center justify-center w-full h-full radio-indicator" />
      </RadioGroupItem>
      <label className="px-2 py-1" htmlFor={areaTitle}>
        {areaTitle}
      </label>
      <div className="radio-background rounded-lg absolute w-full h-full pointer-events-none"></div>
    </div>
  );
}

export function FilterAnalyticsModal(props) {
  const { employees, filter, setFilter } = props;
  const areasSet = getAllCompanyAreas(employees);
  const areas = Array.from(areasSet.values(), (val) => val);
  const unselected = "random-string-used-instead-of-undefined@%#?¿";

  const [selectedArea, setSelectedArea] = useState(unselected);
  const [selectedUser, setSelectedUser] = useState(unselected);

  function selectArea(value) {
    setSelectedArea(value);
    setSelectedUser(unselected);
  }

  function selectUser(value) {
    setSelectedUser(value);
    setSelectedArea(unselected);
  }

  function handleAddFilter() {
    if (selectedArea !== unselected) {
      filter.byArea.add(selectedArea);
      setFilter({ ...filter });
      return;
    }

    if (selectUser !== unselected) {
      filter.byUser.add(selectedUser);
      setFilter({ ...filter });
      return;
    }
  }
  return (
    <DialogPortal>
      <DialogOverlay className="fixed inset-0 bg-stone-950/25" />

      <DialogContent className="w-[90vw] max-w-lg px-7 pt-6 pb-4 z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-stone-300 shadow-lg fixed rounded-md bg-white">
        <div className="flex items-center">
          <AiOutlineBarChart size={24} />
          <DialogTitle className="ml-2 font-medium text-base">
          Adicionar filtro
          </DialogTitle>
        </div>

        <DialogDescription className="sr-only">
        Filtrar estatísticas por integrantes da empresa
        </DialogDescription>

        <form className="text-stone-600">
          <p className="mt-3 text-stone-900">Setores</p>
          <RadioGroup
            className="grid sm:grid-cols-2"
            onValueChange={selectArea}
            value={selectedArea}
          >
            {areas.map((area) => (
              <AreaRadioButton
                key={generateRandomKey()}
                area={area}
                selectedArea={selectedArea}
              />
            ))}
          </RadioGroup>
          <p className="mt-4 text-stone-900">Colaboradores</p>

          <RadioGroup
            className="grid sm:grid-cols-2 max-h-52 overflow-y-scroll"
            onValueChange={selectUser}
            value={selectedUser}
          >
            {employees.map((employee) => (
              <EmployeeRadioButton
                key={generateRandomKey()}
                employee={employee}
              />
            ))}
          </RadioGroup>
          <div className="mt-2 w-full flex justify-end">
            <DialogClose asChild>
              <button
                onClick={handleAddFilter}
                className="px-2 py-1 rounded-md text-stone-200 bg-stone-950"
                type="button"
              >
                Adicionar
              </button>
            </DialogClose>
          </div>
        </form>

        <DialogClose asChild>
          <button className="h-6 w-6 top-2 right-2 absolute flex justify-center items-center rounded-full hover:bg-stone-800/10">
            <AiOutlineClose className="text-stone-600" />
          </button>
        </DialogClose>
      </DialogContent>
    </DialogPortal>
  );
}
