import { useState, useEffect } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import "../Components/LandingPageStyles.css";

export function ReviewsList(props) {
  const { reviews } = props;
  const [index, setIndex] = useState(0);
  const [animationState, setAnimationState] = useState({
    start: false,
    end: false,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 5000); // Cambia a la siguiente reseña cada 5000 milisegundos (5 segundos)

    return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonta
  }, [index]); // Se ejecutará cada vez que index cambie

  async function handleGoBack() {
    let loop;
    if (index <= 0) {
      loop = reviews.length - 1;
    }

    await startTransition("slide-right-out");
    setIndex(loop ?? index - 1);
    await endTransition("slide-left-in");
    resetAnimationState();
  }

  async function handleNext() {
    let loop;
    if (index + 1 >= reviews.length) {
      loop = 0;
    }

    await startTransition("slide-left-out");
    setIndex(loop ?? index + 1);
    await endTransition("slide-right-in");
    resetAnimationState();
  }

  async function startTransition(type) {
    setAnimationState({ ...animationState, start: type });
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  async function endTransition(type) {
    setAnimationState({ ...animationState, end: type });
    await new Promise((resolve) => setTimeout(resolve, 400));
  }

  function resetAnimationState() {
    setAnimationState({ start: "", end: "" });
  }

  return (
    <div>
      <div
        className={`${animationState.start} ${animationState.end} px-4 min-h-[8rem] md:min-h-[12rem]`}
      >
        {reviews[index]}
      </div>

      <div className="flex justify-center">
        <div className="mt-12 flex items-center gap-4">
          <button
            onClick={handleGoBack}
            aria-label="review anterior"
            className="border border-stone-400 p-2 rounded-full"
          >
            <AiOutlineLeft />
          </button>

          {reviews.map((item, i) => (
            <div
              key={i}
              className={`${
                index === i ? "bg-stone-800" : "bg-stone-400"
              } h-1.5 w-1.5 rounded-full `}
            ></div>
          ))}

          <button
            onClick={handleNext}
            aria-label="próxima avaliação"
            className="border border-stone-400 p-2 rounded-full"
          >
            <AiOutlineRight />
          </button>
        </div>
      </div>
    </div>
  );
}
