import { useEffect } from "react";
import { Navbar } from "../landing_page/Components/Navbar";

import "./Components/PaymentPageStyles.css";
import TokenRefresher from "../../components/TokenRefresher";
import { useVerifyToken } from "./Hooks/useVerifyToken";
import { useSelector } from "react-redux";

import { AiFillCheckCircle } from "react-icons/ai";
import {
  AiOutlineWhatsApp,
  AiOutlineMail,
  AiOutlineInstagram,
} from "react-icons/ai";

import { auth } from "../../firebase/FirebaseUtils";
import { Link } from "react-router-dom";
export function PaymentCompleted(props) {
  const token = useSelector((state) => state.login.token);
  const { isVerified, loading, user } = useVerifyToken(token);

  useEffect(() => { });
  return (
    <>
      <div
        id="start"
        className="radial-background relative flex flex-col justify-between w-full"
      >
        <Navbar user={user} />

        <div className="pt-8 min-h-screen flex flex-col items-center text-stone-100">
          <AiFillCheckCircle className="text-5xl sm:text-8xl" />
          <h1 className="mt-4 font-semibold text-2xl md:text-3xl">
            PAGAMENTO BEM-SUCEDIDO
          </h1>
          <div className="px-4 max-w-2xl tracking-wider text-center sm:text-lg">
            <p className="mt-2">
            Obrigado por nos escolheres! Agora podes desfrutar da tua conta Epa
             Digital. Verifica o teu e-mail para mais informações.
            </p>
            <p className="mt-2">
            Se quiseres falar com um operador, contacta-nos pelos seguintes meios:
            </p>
            <div className="mt-4 gap-4 text-4xl flex justify-center">
              <a href="https://wa.me/59169108791?text=Hola!%20acabo%20de%20comprar%20una%20cuenta.%20Me%20gustaría%20más%20información">
                <AiOutlineWhatsApp />
              </a>
              <a href="mailto:ventas@soyyo.digital?subject=Información de compra&body=Me gustaría conocer más">
                <AiOutlineMail />
              </a>
              <a href="https://www.instagram.com/soyyo.digital/">
                <AiOutlineInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
